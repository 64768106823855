import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["phone"]

  connect() {
    new Cleave(this.phoneTarget, {
      phone: true,
      phoneRegionCode: 'us',
      delimiter: '-',
      prefix: '+1'
    });
  }
}
