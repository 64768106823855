import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["filter", "result", "searchItems"]

  async attemptSearch(event) {
    event.preventDefault()
    const value = this.filterTarget.value
    const path = this.data.get("path")
    const request = new FetchRequest('get', `${path}/?search=${value}`)
    await request.perform()
  }
}