import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["amount", "option", "item"]

  connect() {
    console.log(this.optionTargets)
    new Cleave(this.amountTarget, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
    });
  }

  show() {
    this.itemTargets.forEach(item => {
      item.classList.remove("hidden")
    })
  }

  hide() {
    this.itemTargets.forEach(item => {
      item.classList.add("hidden")
    })
  }
}
