// Based on https://onrails.blog/2018/05/08/stimulus-js-tutorial-dont-lose-unsaved-form-fields/

import { Controller } from "stimulus"

const LEAVING_PAGE_MESSAGE = "Your changes will be lost. Are you sure you want to navigate away?"

export default class extends Controller {
  static targets = ['errors',
                      'saveDisabled',
                      'save',
                      'saveAndNext',
                      'saveAndNextDisabled',
                      'saving',
                      'saved',
                      'updateDisabled',
                      'update',
                      'updating',
                      'updated',
                      "addAdditional"
                    ]

    submit(){
      this.saveTarget.classList.add("hidden")
      this.savingTarget.classList.remove("hidden")
    }
}
