import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "token"]

  initlialize() {
    let cardComponent
    let cbInstance
  }

  connect(){
    this.setupChargebee()
    this.createCart()
  }

  setupChargebee() {
    const chargebeeSite  = document.querySelector("meta[name='chargebee_site']").getAttribute("content")
    const publishableKey = document.querySelector("meta[name='publishable_key']").getAttribute("content")

    Chargebee.init({
      site: chargebeeSite,
      publishableKey: publishableKey
    })
    this.cbInstance = Chargebee.getInstance()
  }

  createCart() {
    var options = {
      fonts: [
        'https://fonts.googleapis.com/css?family=Roboto:300,500,600'
      ],

      // add classes for different states
      classes: {
        focus: 'focus',
        invalid: 'invalid',
        empty: 'empty',
        complete: 'complete',
      },

      // add placeholders
      placeholder: {
        number: "Enter Card Number"
      },
      currency: 'USD',
      // Set locale
      locale: 'en',

      style: {

        // Styles for default state
        base: {
          color: '#333',
          fontWeight: '500',
          fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

        },

        // Styles for invalid state
        invalid: {
          color: '#E34918',

          ':focus': {
            color: '#E34918',
          },
          '::placeholder': {
            color: '#E34918',
          },
        },
      },
    }

    this.cbInstance.load("components").then(() => {
      // Create card
      var component = this.cbInstance.createComponent("card", options)
      // Create card fields
      component.createField("number").at("#card-number")
      component.createField("expiry").at("#card-expiry")
      component.createField("cvv").at("#card-cvc")

      // Mount card component
      component.mount()
      this.cardComponent = component
    });
  }

  submitForm(event) {
    event.preventDefault();
    var component = this.cardComponent
    component.tokenize().then(data => {
      this.tokenTarget.value = data.token
      this.formTarget.submit()
    }).catch(error => {
      console.log(error)
    });
  }
}
